body{
  cursor: none;
}

.content-container {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.content-header {
  z-index: 10001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 280px;
  display: flex;
  color: white;
  mix-blend-mode: difference;
  flex-direction: column;
}

.content-header-background {
  position: absolute;
    top: 0;
    left: 0;
    height: 700px;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 40%, rgba(0, 0, 0, 0.2) 60%,rgba(0, 0, 0, 0) 100%);
}

.header-logo {
  font-family: 'Rubik Mono One';
  width: 100%;
  padding: 50px 0;
  height: 100px;
  font-size: 20px;
}

.header-menu {
  width: 100%;
  display: flex;
  font-family: 'Rubik';
  align-items: flex-start;
  font-size: 20px;
  justify-content: center;
}

.header-menu-item {
  z-index: 2;
  cursor: pointer;
  padding-top: 30px;
  margin: 0 6%;
  width: 140px;
}

.header-logo-video {
  height: 400px;
  position: relative;
  top: -150px;
}

.content-footer {
  width: 100%;
  padding-bottom: 80px;
}

.header-menu-item {
  display: block;
  color: white;
  text-decoration: none;
  position: relative;
  height: 30px;
}

.header-menu-item>span:after {
  content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.header-menu-item>span:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.content-background {
  position: relative;
  height: fit-content;
  width: 100%;
  padding-top: 10%;
  margin-bottom: 60px;
}

.content-background-img {
  width: 100%;
}

.iframe-container {
  position: absolute;
  top: 48.5%;
  left: 38%;
  height: 18%;
  width: 23.7%;
  z-index: 10000;
}

.cursor {
  pointer-events: none;
}

.cursor__ball {
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  z-index: 1000;
}

.cursor__ball > svg > circle {
  fill: #f7f8fa;
}

.moving-board{
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.click-animation-container{
  position: absolute;
  left:240px;
  top:340px;
  height: 64px;
  width: 64px;
  transform: rotate(45deg);
}

.click-animation{
  height: 100%;
  width: 100%;
  background-image:url("Assets/click-icon.png");
  background-blend-mode:screen;
  animation: flowing 2s ease-in-out infinite alternate;
}

.click-box-1{
  position: absolute;
  left: 270px;
  top: 320px;
  width: 50px;
  height: 60px;
  z-index: 100;
}

.custom-modal{
  width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  border: 1px solid #4ef542;
  background-color: black;
  color: white;
  z-index: 500;
  animation: fadeIn 1s;
}

.modal-close-icon{
  position: absolute;
  top: 20px;
  right: 28px;
  color: #4ef542;
}

@keyframes flowing {
	0% {
		transform: translateY(0);
	}
  50%{
    transform: translateY(20px);
  }
	100% {
		transform: translateY(0);
	}
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@media only screen and (max-width: 768px) {
  .content-background {
    position: relative;
    height: fit-content;
    width: 100vw;
    padding-top: 60%;
    margin-bottom: 60px;
    height: 450px;
  }  
  .content-background-img{
    height: auto;
    width: 100%;
  }
  .iframe-container {
    position: absolute;
    top: 40.2%;
    left: 23.9%;
    height: 17.5%;
    width: 53.3%;
  }
  .video-carousel-container{
    width: 100% !important;
  }
  .carousel-video{
    height: 260px !important;
    width: 80% !important;
  }
  .carousel-video > iframe{
    height: 100%;
    width: 100%;
  }
  .content-header{
    height: 180px;
  }
  .header-logo{
    height: 50px;
  }
  .header-logo-video{
    height: 300px;
    position: relative;
    top: -100px;
  }
}