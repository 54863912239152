:root {
	--glow: rgba(255, 255, 255, 0.4);
  }

#work-content{
    color: white;
	position: relative;
}

.work-object{
    padding: 50px;
    width: 50%;
    margin:auto;
    border-top: 1px solid white;
}

.work-object-title{
    margin-top: 0;
    font-family: 'Rubik Mono One';
    font-size: 20px;
    margin-bottom: 40px;
}

.text-shadow {
    margin-bottom: 40px;
	text-transform: uppercase;
    font-family: 'Rubik Mono One';
	color: transparent;
	-webkit-text-stroke: #fff;
	-webkit-text-stroke-width: 1px;
    font-size: 30px;
	text-shadow: 2px 2px 10px white;
	transition: all 0.5s ease-in-out;
	text-align: center;
	letter-spacing: 0.2em;
	animation: flicker 0.5s ease-in-out infinite alternate;
}

.text-shadow:hover {
    color: #fff;
}

.carousel-video{
	height: 450px;
	width: 800px;
	margin:auto;
}

.video-carousel-container{
	width: 70%;
	margin:auto;
}

.hexagon {
	z-index: -2;
	position: absolute;
	width: 267px;
	height: 200px;
	background-color: var(--glow);
	margin: 55.3px 0;
	filter: blur(20px);
  }
  .hexagon:before,
  .hexagon:after {
	content: "";
	position: absolute;
	left: 50%;
    transform: translateX(-50%);
	width: 0;
	border-left: 106px solid transparent;
	border-right: 106px solid transparent;
  }
  .hexagon:before {
	bottom: 100%;
	border-bottom: 55.3px solid  var(--glow);
  }
  .hexagon:after {
	top: 100%;
	width: 0;
	border-top: 55.3px solid  var(--glow);
  }
  .back {
	height: 20rem;
	width: 20rem;
	position:absolute;
	top:0;
	left:0;
	background-image: url("ms/msBackground.png");
	background-size: cover;
	background-position: center;
	z-index: -1;
  }
  .top {
	height: 20rem;
	width: 20rem;
	position:absolute;
	top:0;
	left:0;
	background-image: url("ms/msTop.png");
	background-size: cover;
	background-position: center;
	z-index: 1;
  }
  .left {
	height: 20rem;
	width: 20rem;
	position:absolute;
	top:0;
	left:0;
	background-image: url("ms/msLeft.png");
	background-size: cover;
	background-position: center;
	z-index: 1;
  }
  .right {
	height: 20rem;
	width: 20rem;
	position:absolute;
	top:0;
	left:0;
	background-image: url("ms/msRight.png");
	background-size: cover;
	background-position: center;
	z-index: 1;
  }
  .track-bg{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
  }
  #cube {
	margin: auto;
	margin-top: 120px;
	margin-bottom: 120px;
	animation: hover 1.5s ease-in-out infinite alternate;
	transition: transform 300ms;
	animation-play-state: running;
	height: 20rem;
	width: 20rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .cube-overlay{
	margin: auto;
	animation: hover 1.5s ease-in-out infinite alternate;
	transition: transform 300ms;
	animation-play-state: running;
	height: 20rem;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	pointer-events: none;
  }
.track-overlay{
	position: absolute;
	top:0;
	left:0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.4);
}
.track-title{
	font-size: 25px;
}
.track-title:hover{
	text-decoration: underline;
}

  @keyframes hover {
	from {
	  transform: translateY(-0.5rem);
	}
	to {
	  transform: translateY(0.5rem);
	}
  }
  .powerup {
	border-radius: 50%;
	z-index: 100;
	overflow: hidden;
	height: 200px;
	width: 200px;
	z-index: 100;
	position: relative;
	pointer-events: all;
  }

@keyframes flicker {
	0% {
		opacity: 0.5;
		text-shadow: 2px 2px 10px white;
	}
	100% {
		opacity: 1;
		text-shadow: 2px 2px 20px white;
	}
}

@media only screen and (max-width: 768px) {
	.hexagon {
		z-index: -2;
		position: absolute;
		width: 213px;
		height: 160px;
		background-color: var(--glow);
		margin: 44.2px 0;
		filter: blur(20px);
	  }
	  .hexagon:before,
	  .hexagon:after {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		border-left: 106px solid transparent;
		border-right: 106px solid transparent;
	  }
	  .hexagon:before {
		bottom: 100%;
		border-bottom: 44.2px solid  var(--glow);
	  }
	  .hexagon:after {
		top: 100%;
		width: 0;
		border-top: 44.2px solid  var(--glow);
	  }
	  .back {
		height: 16rem;
		width: 16rem;
	  }
	  .top {
		height: 16rem;
		width: 16rem;
	  }
	  .left {
		height: 16rem;
		width: 16rem;
	  }
	  .right {
		height: 16rem;
		width: 16rem;
	  }
	  #cube {
		margin: auto;
		margin-top: 120px;
		margin-bottom: 120px;
		height: 16rem;
		width: 16rem;
	  }
	  .cube-overlay{
		height: 16rem;
	  }
	  .powerup {
		height: 160px;
		width: 160px;
	  }
	  .track-title{
		font-size: 20px;
	  }
}