.about-content {
    width: 100%;
    color: white;
}

.about-name {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 320px;
    mix-blend-mode: difference;
    z-index: 100;
}

.about-name-span {
    font-size: 10.8vw;
    font-family: 'Rubik Mono One';
}

.about-first-pic {
    width: 100%;
}

.about-first-pic-img {
    width: 92%;
    padding-top: 500px;
}

.about-desc {
    width: 60%;
    text-align: start;
    margin: auto;
    font-size: 35px;
    margin-top: 200px;
    font-family: 'Rubik';
    padding-bottom: 200px;
    position: relative;
}

.about-desc-title {
    font-size: 35px;
    z-index: 10;
    position: relative;
    mix-blend-mode: difference;
}

.about-desc-body {
    z-index: 10;
    position: relative;
    mix-blend-mode: difference;
    background: rgba(156, 156, 156, 0.1);
}

.float-1 {
    position: absolute;
    top: 120px;
    left: -150px;
    width: 360px;
}

.float-2 {
    position: absolute;
    top: -50px;
    right: -150px;
    width: 360px;
}

.carousel-container {
    width: 1000px;
    margin: auto;
}

.carousel-image {
    height: 700px;
    width: 1000px;
}

.carousel-image>img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}


@media only screen and (max-width: 768px) {
    .about-name{
        padding-top: 240px;
    }
    .carousel-container {
        width: 100%;
    }

    .carousel-image {
        height: 340px;
        width: 80%;
        margin: auto;
    }

    .about-desc {
        width: 80%;
        margin: auto;
        font-size: 21px;
        margin-top: 100px;
        font-family: 'Rubik';
        padding-bottom: 100px;
        position: relative;
    }
    .float-1{
        position: absolute;
        top: 300px;
        left: -15px;
        width: 60%;
    }
    .float-2 {
        position: absolute;
        top: -20px;
        right: -15px;
        width: 60%;
    }
    .about-first-pic-img{
        padding-top: 300px;
    }
}